import React, { useState, useEffect } from "react";
import Table from "./index";
import UserModal from "./UserModal";

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    fetch("api/users")
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res) {
          let tableData = res.map((record) => ({
            ...record,
            isActive: record.isActive ? "Si" : "No",
            isAdmin: record.isAdmin ? "Si" : "No",
          }));
          tableData = tableData.map(({ roles, updateAt, ...record }) => record);
          //console.log(tableData)
          setUsers(tableData);
        }
      })
      .catch((error) => {
        setError(error.message);
        setUsers([]);
        setLoading(false);
      });
  }, []);

  const handleDelete = (user) => {
    console.log(user);
  };

  const handleCreate = () => {
    this.handleSaveUser([]);
  };

  const handleEdit = (user) => {
    console.log(user);
    handleSaveUser(user);
  };

  const handleSaveUser = (user) => {
    const existingUser = users.find((u) => u.id === user.id);

    if (existingUser) {
      const updatedUsers = users.map((u) => (u.id === user.id ? user : u));
      setUsers(updatedUsers);
    } else {
      setUsers([...users, user]);
    }
  };

  const columns = ["Id", "Nombre", "Email", "Activo", "Admin", "Registrado"];

  return (
    <>
      <Table
        columns={columns}
        data={users}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onCreate={handleCreate}
        onHandleSaveUser={handleSaveUser}
        onLoading={loading}
        onError={error}
      />
      <UserModal onSaveUser={handleSaveUser} />
    </>
  );
};

export default UsersTable;
