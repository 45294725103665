import React from "react";
import { Link } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";

const Table = ({ columns, data, onEdit, onDelete, onCreate, onLoading, onError }) => {
  return (
    <div className="col-md-12 col-lg-8 main-table">
      <div className="text-end">
        <button className="btn btn-primary py-3" onClick={onCreate}>
          <i className="fas fa-plus"></i> Crear nuevo
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>{column}</th>
            ))}
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {onError && <Message variant="alert-danger">{onError}</Message>}
          {onLoading && <Loading />}
          {data &&
            data.map((item, index) => (
              <tr key={`${item.id}-${index}`}>
                {Object.values(item).map((value, valueIndex) => (
                  <td key={`${value}-${valueIndex}`}>{value}</td>
                ))}
                <td className="text-center">
                  <div className="dropdown">
                    <Link to="" data-bs-toggle="dropdown" className="btn btn-light">
                      <i className="fas fa-ellipsis-h"></i>
                    </Link>
                    <div className="dropdown-menu">
                      <button className="dropdown-item text-info" onClick={() => onEdit(item)}>
                        <i className="fas fa-pen"> Editar</i>
                      </button>
                      <button className="dropdown-item text-danger" onClick={() => onDelete(item)}>
                        <i className="fas fa-trash-alt"> Eliminar</i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
