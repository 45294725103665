import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

const Sidebar = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <div>
      <aside className="navbar-aside" id="offcanvas_aside">
        <div className="aside-top">
          <Link to="/" className="brand-wrap">
            <img
              src="/images/logo.png"
              style={{ height: "46" }}
              className="logo"
              alt="Ecommerce dashboard template"
            />
          </Link>
          <div>
            <button className="btn btn-icon btn-aside-minimize">
              <i className="text-muted fas fa-stream"></i>
            </button>
          </div>
        </div>

        <nav>
          <ul className="menu-aside">
            {userInfo && userInfo.isAdmin ? (
              <>
                <li className="menu-item">
                  <NavLink activeClassName="active" className="menu-link" to="/" exact={true}>
                    <i className="icon fas fa-home"></i>
                    <span className="text">Dashboard</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink activeClassName="active" className="menu-link" to="/products">
                    <i className="icon fas fa-shopping-bag"></i>
                    <span className="text">Productos</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink activeClassName="active" className="menu-link" to="/addproduct">
                    <i className="icon fas fa-cart-plus"></i>
                    <span className="text">Agregar producto</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink activeClassName="active" className="menu-link" to="/orders">
                    <i className="icon fas fa-bags-shopping"></i>
                    <span className="text">Ordenes</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink activeClassName="active" className="menu-link" to="/users">
                    <i className="icon fas fa-user"></i>
                    <span className="text">Usuarios</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink activeClassName="active" className="menu-link" to="/prompts">
                    <i className="icon fas fa-book"></i>
                    <span className="text">Prompts</span>
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    activeClassName="active"
                    className="menu-link disabled"
                    to="/transaction"
                  >
                    <i className="icon fas fa-usd-circle"></i>
                    <span className="text">Transactiones</span>
                  </NavLink>
                </li>
              </>
            ) : null}
            {userInfo ? (
              <>
                <li className="menu-item">
                  <NavLink activeClassName="active" className="menu-link" to="/services/telegram">
                    <i className="icon fab fa-telegram"></i>
                    <span className="text">Cuenta Telegram</span>
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink activeClassName="active" className="menu-link" to="/user/profile">
                    <i className="icon fas fa-shopping-bag"></i>
                    <span className="text">Actualizar perfil</span>
                  </NavLink>
                </li>
              </>
            ) : null}
          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
