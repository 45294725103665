import React from "react";
//import CategoriesTable from "./CategoriesTable";
import UsersTable from "../Table/UsersTable";

const MainCategories = () => {
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Categories</h2>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            {/* Create category */}
            {/* <CreateCategory /> */}
            {/* Categories table */}
            <UsersTable />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainCategories;
