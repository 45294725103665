import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Loading from "../components/LoadingError/Loading";
import Toast from "../components/LoadingError/Toast";
import { register } from "../Redux/Actions/userActions";
import Message from "./../components/LoadingError/Error";
import HeaderLogin from '../components/HeaderLogin'

const Register = ({ location, history }) => {
  window.scrollTo(0, 0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error } = userRegister;

  useEffect(() => {
    console.log(userInfo);
    if (userInfo) {
      history.push("/");
    }
  }, [userInfo, history ]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(register(name, email, password));
  };
  return (
    <>
      <HeaderLogin/>
      <Toast />
      <div
        className="card shadow mx-auto"
        style={{ maxWidth: "380px", marginTop: "100px" }}
      >
        <div className="card-body">
          {error && <Message variant="alert-danger">{error}</Message>}
          {loading && <Loading />}
          <h4 className="card-title mb-4 text-center">Registro</h4>
          <form onSubmit={submitHandler}>
          <div className="mb-3">
              <input
                className="form-control"
                placeholder="Nombre completo"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <input
                className="form-control"
                placeholder="Correo electronico"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <input
                className="form-control"
                placeholder="Contraseña"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <button type="submit" className="btn btn-primary w-100">
                Registrarse
              </button>
              <p>
            <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
              Tengo una cuenta <strong>Acceder</strong>
            </Link>
          </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
