import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./../components/sidebar";
import Loading from "../components/LoadingError/Loading";
import Message from "./../components/LoadingError/Error";
import Toast from "../components/LoadingError/Toast";
import { toast } from "react-toastify";
import { addTelegram } from "../Redux/Actions/userActions"
import { USER_TELEGRAM_UPDATE_RESET } from '../Redux/Constants/UserContants';
import Header from "../components/Header";
import { getIdTelegramUser } from '../services/getIdTelegramUser';

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const ServiceTelegramScreen = () => {
  window.scrollTo(0, 0);
  const [nombre, setNombre] = useState("");
  const [telegramid, setTelegramid] = useState("");
  const [userid, setUserid] = useState("");
  // const [error, setError] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateTelegram = useSelector((state) => state.userUpdateTelegram);
  const { userTelegram, loading, error } = userUpdateTelegram;

  useEffect(() => {
      dispatch({ type: USER_TELEGRAM_UPDATE_RESET });
      setNombre(userInfo.name);
      setUserid(userInfo.id);
      getIdTelegramUser(userInfo.id).then((user) => {
        if (user) {
          setTelegramid(user.telegramid);
         // setError(null);
        }
      }).catch((error) => {
        console.log(error.message);
       // setError("No tiene registrado el TelegramId");
        toast.warn("No tiene registrado el TelegramId", ToastObjects);
      });
  }, [userInfo, userTelegram, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    //setloading(true);
    dispatch(addTelegram(nombre, telegramid, userid));
  };
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <Toast />
        <div className="card shadow mx-auto" style={{ maxWidth: "380px", marginTop: "100px" }}>
          <div className="card-body">
            {error && <Message variant="alert-warning">{error}</Message>}
            {loading && <Loading />}
            <h4 className="card-title mb-4 text-center">Registro Id Telegram</h4>
            <form onSubmit={submitHandler}>
              <label>Nombre del Perfil:</label>
              <div className="mb-3">
                <input
                  className="form-control"
                  placeholder="Nombre completo"
                  type="text"
                  value={nombre}
                  onChange={e => setNombre(e.target.value)}
                  required
                  disabled
                />
              </div>
              <label>ID Telegram:</label>
              <div className="mb-3">
                <input
                  className="form-control"
                  placeholder="ID Telegram"
                  type="text"
                  value={telegramid}
                  onChange={(e) => setTelegramid(e.target.value)}
                  required
                />
              </div>

              <div className="mb-4">
                <button type="submit" className="btn btn-primary w-100">
                  Registrar ID de Telegram
                </button>
                <p></p>
              </div>
            </form>
          </div>
        </div>
        <div className="card shadow mx-auto" style={{ maxWidth: "380px", marginTop: "40px" }}>
          <div className="card-body">
          <p>Para obtener el ID de Telegram, solo escriba e inicie nuestro bot <a href="https://t.me/tuchatgpt_bot" style={{ color: '#4fa607' }}>@tuchatgpt_bot</a></p>
          </div>
          </div>
      </main>
    </>
  );
};

export default ServiceTelegramScreen;
