import React, { useState } from 'react';

function UserModal({ onSaveUser }) {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({ id: '', name: '' });

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleSaveUser = () => {
    onSaveUser(user);
    setUser({ id: '', name: '' });
    handleCloseModal();
  };

  const handleChangeId = (event) => {
    setUser({ ...user, id: event.target.value });
  };

  const handleChangeName = (event) => {
    setUser({ ...user, name: event.target.value });
  };

  return (
    <div>
      <button onClick={handleOpenModal}>Agregar Usuario</button>
      {isOpen && (
        <div>
          <h2>{user.id ? 'Editar Usuario' : 'Agregar Usuario'}</h2>
          <label htmlFor="id">ID:</label>
          <input
            type="text"
            id="id"
            value={user.id}
            onChange={handleChangeId}
          />
          <label htmlFor="name">Nombre:</label>
          <input
            type="text"
            id="name"
            value={user.name}
            onChange={handleChangeName}
          />
          <button onClick={handleSaveUser}>
            {user.id ? 'Guardar cambios' : 'Agregar'}
          </button>
          <button onClick={handleCloseModal}>Cancelar</button>
        </div>
      )}
    </div>
  );
}

export default UserModal;
