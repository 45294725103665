import React, { useState, useEffect } from "react";
import Table from "./index";
import UserModal from "./UserModal";

const PromptTable = () => {
  const [prompts, setPrompt] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    fetch("api/telegram/prompt")
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          let tableData = res.map((record) => ({
            id: record._id,
            botname: record.botname,
            teltk: record.teltk,
            prompt: record.prompt,
          }));
          setPrompt(tableData);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(error.message);
        setPrompt([]);
        setLoading(false);
      });
  }, []);

  const handleDelete = (prompt) => {
    console.log(prompt);
  };

  const handleCreate = () => {
    this.handleSaveUser([]);
  };

  const handleEdit = (prompt) => {
    console.log(prompt);
    handleSavePrompt(prompt);
  };

  const handleSavePrompt = (prompt) => {
    const existingPrompt = prompts.find((u) => u.id === prompt.id);

    if (existingPrompt) {
      const updatedPrompts = prompts.map((u) => (u.id === prompt.id ? prompt : u));
      setPrompt(updatedPrompts);
    } else {
      setPrompt([...prompts, prompt]);
    }
  };

  const columns = ["ID", "Botname", "Teltk", "Prompt"];

  return (
    <>
      <Table
        columns={columns}
        data={prompts}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onCreate={handleCreate}
        onHandleSaveUser={handleSavePrompt}
        onLoading={loading}
        onError={error}
      />
      <UserModal onSaveUser={handleSavePrompt} />
    </>
  );
};

export default PromptTable;
