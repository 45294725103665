import React, { useEffect } from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CategoriesScreen from "./screens/CategoriesScreen";
import OrderScreen from "./screens/OrderScreen";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import AddProduct from "./screens/AddProduct";
import Login from "./screens/LoginScreen";
import Register from "./screens/RegisterScreen";
import UsersScreen from "./screens/UsersScreen";
import PromptScreen from "./screens/PromptScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import ServiceTelegramScreen from "./screens/ServiceTelegramScreen";

import NotFound from "./screens/NotFound";
import { PrivateRouter, AdminRouter } from "./Routers";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "./Redux/Actions/ProductActions";
import { listOrders } from "./Redux/Actions/OrderActions";

function App() {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listProducts());
      dispatch(listOrders());
    } else if (userInfo) {
      dispatch(listProducts());
    }
  }, [dispatch, userInfo]);

  return (
    <>
      <Router>
        <Switch>
          <PrivateRouter path="/" component={HomeScreen} exact />

          <AdminRouter path="/users" component={UsersScreen} />
          <AdminRouter path="/prompts" component={PromptScreen} />
          <PrivateRouter path="/user/profile" component={ProfileScreen} />
          <PrivateRouter path="/addUser" component={AddProduct} />
          <PrivateRouter
            path="/user/:id/edit"
            component={ProductEditScreen}
          />

          <PrivateRouter path="/products" component={ProductScreen} />
          <PrivateRouter path="/addproduct" component={AddProduct} />
          <PrivateRouter
            path="/product/:id/edit"
            component={ProductEditScreen}
          />
          <PrivateRouter path="/category" component={CategoriesScreen} />
          
          <PrivateRouter path="/orders" component={OrderScreen} />
          <PrivateRouter path="/order/:id" component={OrderDetailScreen} />

          <PrivateRouter path="/clientes" component={OrderScreen} />

          <PrivateRouter path="/services/telegram" component={ServiceTelegramScreen} />
          
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <PrivateRouter path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
